import React from "react";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import { CardTemplate } from "../components/Card/Card";
import { Footer } from "./Footer/Footer";
import { Navbar } from "./Navbar/Navbar";

class User extends React.Component {
  constructor(props) {
    console.log("props-->", props);
    super(props);
    this.state = {
      user: [],
      url: [],
      pictures: [],
      attendees: [],
      facebookPicture: [],
      googlePicture: [],
      currentUser: props.current,
    };
  }

  componentDidMount() {
    axios
      .get(`/api/v1/all/attendees`)
      .then((response) => this.setState({ attendees: response.data }))
      .catch((error) => console.log(error));

    axios
      .get(`/api/v1/users/${this.props.user}/profile`)
      .then((response) => {
        console.log(response);
        this.setState({
          user: response.data,
          url: response.data.photo.url,
          facebookPicture: response.data.facebook_picture_url,
          googlePicture: response.data.google_url,
          date: response.data.created_at.slice(0, 10),
        });
      })
      .catch((error) => console.log(error));
    this.setState({ pictures: this.props.pictures });
  }

  render() {
    const { user, url, pictures, facebookPicture, googlePicture, currentUser } =
      this.state;
    const { name, location, bio, created_at } = user;

    const today = new Date();
    const userJoined = new Date(created_at);
    const getDays = Math.round((today - userJoined) / (1000 * 60 * 60 * 24));

    let user_picture = null;

    if (url) {
      user_picture = <Avatar className="user-avatar-mobile" src={url} />;
    } else if (facebookPicture) {
      user_picture = (
        <Avatar className="user-avatar-mobile" src={facebookPicture} />
      );
    } else if (googlePicture) {
      user_picture = (
        <Avatar className="user-avatar-mobile" src={googlePicture} />
      );
    } else {
      user_picture = <Avatar className="user-avatar-mobile" />;
    }

    const { meetups } = this.props;
    const { attendees } = this.state;
    const userMeetups = meetups.map((meetup, index) => {
      return attendees.filter((attendee) => attendee.meetup_id === meetup.id);
    });

    const userAttendedCount = attendees.filter(
      (attendee) => parseInt(attendee.user_id) === user.id
    ).length;

    return (
      <div>
        <Navbar user={currentUser} />
        {window && window.innerWidth >= 850 ? (
          <div className="user-page-container">
            <div className="user-page-information">
              <svg
                fill="#ffe8c9"
                className="user-triangle"
                height="60vw"
                width="60vw"
                version="1.1"
                id="Layer_1"
                viewBox="0 0 511.51 511.51"
                stroke="#c4c4c4"
                stroke-width="0.005115090000000001"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="1.023018"
                />

                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <g>
                      {" "}
                      <path d="M498.675,493.845L265.16,5.568c-3.541-7.424-15.701-7.424-19.243,0L11.251,496.235c-1.579,3.307-1.344,7.189,0.597,10.283 s5.355,4.992,9.024,4.992h469.76c5.888,0,10.667-4.779,10.667-10.667C501.299,498.176,500.317,495.723,498.675,493.845z" />{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
              <div className="user-information-wrapper">
                <div className="user-information-container">
                  <div className="user-picture-container">{user_picture}</div>
                  {location && (
                    <p className="user-profile-location">{location}</p>
                  )}
                  {bio && <div className="user-biography">{bio}</div>}
                  <img
                    className="user-profile-image"
                    src="/assets/planet-earth.png"
                    alt="plant-image"
                  />
                </div>
              </div>
            </div>

            <div className="user-data">
              <div className="user-data-attended" data-user="attended">
                {userMeetups.length}
                <p className="user-data-joined-text">Meetings Organized</p>
              </div>
              <div className="user-data-hosted" data-user="hosted">
                {userAttendedCount}
                <p className="user-data-joined-text">Meetings Attended</p>
              </div>
              <div className="user-data-joined" data-user="joined">
                <div>{getDays}</div>
                <p className="user-data-joined-text">Active days</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="user-page-container-mobile">
            <div className="user-page-information-mobile">
              <svg
                fill="#ffe8c9"
                className="user-triangle-mobile"
                height="470px"
                width="470px"
                version="1.1"
                id="Layer_1"
                viewBox="0 0 511.51 511.51"
                stroke="#c4c4c4"
                stroke-width="0.005115090000000001"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke="#CCCCCC"
                  stroke-width="1.023018"
                />

                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <g>
                      {" "}
                      <path d="M498.675,493.845L265.16,5.568c-3.541-7.424-15.701-7.424-19.243,0L11.251,496.235c-1.579,3.307-1.344,7.189,0.597,10.283 s5.355,4.992,9.024,4.992h469.76c5.888,0,10.667-4.779,10.667-10.667C501.299,498.176,500.317,495.723,498.675,493.845z" />{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
              <div className="user-information-container-mobile">
                <div className="user-picture-container">{user_picture}</div>
                {location && (
                  <p className="user-profile-location-mobile">{location}</p>
                )}
                {bio && <div className="user-biography-mobile">{bio}</div>}
                <img
                  className="user-profile-image-mobile"
                  src="/assets/planet-earth.png"
                  alt="plant-image"
                />
              </div>
            </div>
            <div className="user-data-mobile">
              <div className="user-data-attended-mobile" data-user="attended">
                <p className="user-data-attended-mobile-number">
                  {userMeetups.length}
                </p>
                <p className="user-data-attended-mobile-text">
                  Meetings Organized
                </p>
              </div>
              <div className="user-data-hosted-mobile" data-user="hosted">
                <p className="user-data-attended-mobile-number">
                  {userAttendedCount}
                </p>
                <p className="user-data-hosted-mobile-text">
                  Meetings Attended
                </p>
              </div>
              <div className="user-data-joined-mobile" data-user="joined">
                <p className="user-data-attended-mobile-number">{getDays}</p>
                <p className="user-data-joined-mobile-text">Active days</p>
              </div>
            </div>
          </div>
        )}

        <div className="footer-container">
          <Footer />
        </div>
      </div>
    );
  }
}

export default User;
